import React, { Suspense, useEffect, useState, lazy } from "react";

// Libraries
import { Routes, Route, useLocation } from "react-router-dom";
import retina from "retinajs";
import { AnimatePresence } from "framer-motion";

// Context
import GlobalContext from "./Context/Context";

// Components
import ScrollToTopButton from "./Components/ScrollToTop"

// Home
const HomePage = lazy(() => import("./Pages/Home/Home"));

//Services 

const GraphicDesignPage = lazy(() => import("./Pages/Services/GraphicDesign"));

const WebDevelopmentPage = lazy(() => import("./Pages/Services/WebDevelopment"));

const GraphicDesignPage1 = lazy(() => import("./Pages/Services/GraphicDesign-1"));

const WebDevelopment1 = lazy(() => import("./Pages/Services/WebDevelopment-1"));

const AppDevelopment = lazy(() => import("./Pages/Services/AppDevelopment"));


// About 

const AboutPage = lazy(() => import("./Pages/About/About"));

// Contact 
const ContactPage = lazy(() => import("./Pages/Contact/Contact"));






function App() {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customModal, setCustomModal] = useState({
    el: null,
    isOpen: false
  })
  const location = useLocation();

  // RetinaJS
  useEffect(() => {
    window.addEventListener('load', retina(document.querySelectorAll('img')));
  }, [])

  useEffect(() => {
    setTimeout(() => {
      import("./Functions/Utilities").then(module => {
        module.SetHeaderMenuPos()
        module.setDocumentFullHeight()
      })
    }, 1000);
  }, [location])

  useEffect(() => {
    if (isModalOpen === true) {
      document.querySelector("body").classList.add("overflow-hidden");
    } else {
      document.querySelector("body").classList.remove("overflow-hidden");
    }
  }, [isModalOpen]);

  // Get the current location and set the window to top
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    setFooterHeight(0);
    setCustomModal({
      ...customModal,
      el: null,
      isOpen: false
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <GlobalContext.Provider
      value={{
        headerHeight,
        setHeaderHeight,
        footerHeight,
        setFooterHeight,
        isModalOpen,
        setIsModalOpen,
        customModal,
        setCustomModal,
      }}
    >
      <div className="App" style={{ "--header-height": `${headerHeight}px` }}>
        {
          <main style={{ marginTop: headerHeight, marginBottom: footerHeight }}>
            <ScrollToTopButton />
            <AnimatePresence exitBeforeEnter>
              <Suspense fallback={<></>}>
                <Routes>
                  {/* Home Page */}

                  <Route path="/" element={<HomePage style={{ "--base-color": "#00A99D" }} />} />
                  
                  {/* End Home page */}


                  {/* About Page */}

                  <Route path="/about" element={<AboutPage style={{ "--base-color": "#00A99D" }} />} />

                  {/* End About page */}

                  {/* Contact Page */}

                  <Route path="/contact-us" element={<ContactPage style={{ "--base-color": "#00A99D" }} />} />

                  {/* End Contact Page */}


                  {/* Services Page */}

                  {/* <Route path="/graphic-design" element={<GraphicDesignPage style={{ "--base-color": "#00A99D" }} />} /> */}

                  {/* <Route path="/web-development" element={<WebDevelopmentPage style={{ "--base-color": "#00A99D" }} />} /> */}

                  <Route path="/graphic-design-1" element={<GraphicDesignPage1 style={{ "--base-color": "#00A99D" }} />} />

                  <Route path="/web-development-1" element={<WebDevelopment1 style={{ "--base-color": "#00A99D" }} />} />

                  <Route path="/app-development" element={<AppDevelopment style={{ "--base-color": "#00A99D" }} />} />

                  {/* End Services Page */}

                </Routes>
              </Suspense>
            </AnimatePresence>
          </main>
        }
      </div>
    </GlobalContext.Provider>
  )
}

export default App;